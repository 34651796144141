import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { Alert } from "react-bootstrap";
import * as QueryString from "query-string";
import { FormattedMessage } from "react-intl";

import "./EmailVerificationView.css";

type Props = {};

class EmailVerificationView extends React.Component<
  RouteComponentProps<Props>,
  {}
> {
  render() {
    const params = QueryString.parse(this.props.location.search);
    return (
      <div id="email-verification-view" className="w-100 h-100 d-block">
        <div className="align-middle rounded-lg border m-2 m-sm-5 p-5 p-sm-4 shadow-sm bg-white">
          <div className="text-center">
            <FontAwesomeIcon
              icon={faEnvelope}
              className="primary-color"
              size="4x"
            />
            <h4 className="my-4">
              <FormattedMessage id="views.email-verification.text.title" />
            </h4>
            <p>
              <FormattedMessage id="views.email-verification.text.why-verification" />
            </p>
            {params.error ? (
              <p className="my-3">
                <Alert variant="danger">{params.error}</Alert>
              </p>
            ) : (
              <Alert variant="success">
                <FormattedMessage id="views.email-verification.text.email-valid" />
              </Alert>
            )}
            <div className="my-4">
              <span className="primary-color">
                <FormattedMessage id="views.email-verification.text.goto-app" />
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EmailVerificationView;
