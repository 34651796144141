import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { FontAwesomeIcon as Fa } from "@fortawesome/react-fontawesome";
import { faLanguage } from "@fortawesome/free-solid-svg-icons";
import "./HomeView.css";
import { AppContext } from "../../contexts/AppContext";

class HomeView extends React.Component {
  render() {
    return (
      <AppContext.Consumer>
        {(state) => (
          <div id="home-view" className="w-100 h-100 d-block">
            <div className="align-middle rounded-lg border m-2 m-sm-5 p-3 p-sm-4 shadow-sm bg-white">
              <Row>
                <Col md>
                  <div className="title mt-3">
                    <span>Ovni</span>
                    <span className="bold">pay</span>
                  </div>
                  <div className="text-muted my-2 text-uppercase">
                    <FormattedMessage id="views.home.text.what-for" />
                  </div>
                  <Image
                    className="m-auto d-block my-2"
                    src={`${process.env.PUBLIC_URL}/assets/img/undraw_mobile_pay.svg`}
                    height={150}
                  />
                  <h4 className="my-4">
                    <FormattedMessage id="views.home.title" />
                  </h4>
                  <p className="my-3">
                    <p>
                      <FormattedMessage id="views.home.text.desc-1" />
                    </p>
                    <p>
                      <FormattedMessage id="views.home.text.desc-2" />
                    </p>
                    <p>
                      <FormattedMessage id="views.home.text.desc-3" />
                    </p>
                  </p>
                  <div className="text-muted my-2 text-uppercase">
                    <FormattedMessage id="views.home.text.available" />
                  </div>
                  <div>
                    <a href="https://play.google.com/store/apps/details?id=com.ovnicode.uideas">
                      <Image
                        src={`${process.env.PUBLIC_URL}/assets/img/googleplay.png`}
                        height={50}
                      />
                    </a>
                  </div>
                </Col>
                <Col md></Col>
              </Row>
              <div className="row">
                <div className="col-12 py-3 text-center">
                  <span className="text-muted d-block">
                    <FormattedMessage id="views.home.text.powered" />
                    {/* powered
                Powered with{" "}
                <span role="img" aria-label="heart">
                  ❤️
                </span>{" "}
                by Ovnicode */}
                  </span>
                  <span className="d-block reach-us">
                    <FormattedMessage id="views.home.text.reach-us" />{" "}
                    <a
                      href="mailto:team@ovnicode.com"
                      className="secondary-color"
                    >
                      team@ovnicode.com
                    </a>
                  </span>
                  <div className="py-3 text-center text-mutted language-switcher">
                    <span
                      className={
                        state.locale.startsWith("en")
                          ? "primary-color"
                          : "cursor-pointer"
                      }
                      onClick={
                        state.locale.startsWith("en")
                          ? undefined
                          : (
                              event: React.MouseEvent<HTMLSpanElement, MouseEvent>
                            ) => {
                              state.switchLanguage("en");
                            }
                      }
                    >
                      English
                    </span>
                    <Fa icon={faLanguage} className="mx-2" size="2x" />
                    <span
                      className={
                        state.locale.startsWith("fr")
                          ? "primary-color"
                          : "cursor-pointer"
                      }
                      onClick={
                        state.locale.startsWith("fr")
                          ? undefined
                          : (
                            event: React.MouseEvent<HTMLSpanElement, MouseEvent>
                          ) => {
                            state.switchLanguage("fr");
                          }
                      }
                    >
                      Français
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </AppContext.Consumer>
    );
  }
}

export default HomeView;
