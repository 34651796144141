import App from "../App";

/**
* Switch app language
* @param value new language tag
*/
export const switchLanguage = (app: App) => {
    return (value: string) => {
        app.updateState({
            locale: value.split(/[-_]/)[0],
        });
    }
}