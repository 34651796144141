import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import HomeView from "./views/home/HomeView";
import { IntlProvider } from "react-intl";
import en from "./translations/en.json";
import fr from "./translations/fr.json";
import { flattenMessages } from "./common/Utils";
import { AppContext } from "./contexts/AppContext";
import { switchLanguage } from "./contexts/AppContextMethods";
import EmailVerificationView from "./views/email-verification/EmailVerificationView";

const messages: any = {
  en: flattenMessages(en),
  fr: flattenMessages(fr),
};

export type AppState = {
  locale: string;
  switchLanguage: (value: string) => void;
};

const CustomRoute = (props: any) => {
  const { component: Component, ...rest } = props;

  return (
    <Route {...rest} render={(routeProps) => <Component {...routeProps} />} />
  );
};

class App extends React.Component<{}, AppState> {
  _isMounted: boolean = false;

  constructor(props: any) {
    super(props);
    this.state = {
      locale: navigator.language.split(/[-_]/)[0],
      switchLanguage: switchLanguage(this),
    };

    this.updateState = this.updateState.bind(this);
  }

  /**
   * Update app state safely
   */
  updateState(state: Partial<AppState>) {
    if (this._isMounted) {
      this.setState(state as any);
    }
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return (
      <AppContext.Provider value={this.state}>
        <IntlProvider
          locale={this.state.locale}
          messages={messages[this.state.locale]}
        >
          <Router>
            <Switch>
              <CustomRoute exact path="/email-verification" component={EmailVerificationView} />
              <Route exact path="/">
                <HomeView />
              </Route>
              <Route path="*">
                <HomeView />
              </Route>
            </Switch>
          </Router>
        </IntlProvider>
      </AppContext.Provider>
    );
  }
}

export default App;
